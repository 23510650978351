<template>
  <div>
    <save
      ref="saveForm"
      @onSuccess="getItems"
    />
    <b-overlay :show="loading">
      <good-table-column-search
        :items="items.data"
        :columns="columns"
        :total="items.total"
        :page="page"
        :filter="filterModel"
        @getItems="getItems"
        @add="$refs.saveForm.visible = true"
        @edit="(item) => $refs.saveForm.edit(item)"
        @delete="destroy"
        @onPageChange="(p) => page = p"
      >

        <template
          slot="table-row"
          slot-scope="props"
        >
          {{ props }}
        </template>
      </good-table-column-search>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { showToast } from '@/utils/toast'
import GoodTableColumnSearch from '@/views/table/vue-good-table/GoodTableColumnSearch.vue'
import Save from './save.vue'

export default {
  name: 'Index',
  components: {
    GoodTableColumnSearch, Save,
  },
  data() {
    return {
      page: 1,
      loading: false,
      filterModel: {
        per_page: 50,
      },
    }
  },
  computed: {
    ...mapGetters({
      items: 'role/GET_ITEMS',
    }),
    columns() {
      return [
        {
          label: '#',
          field: 'row_number',
        },
        {
          label: this.$t('Имя'),
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Описание'),
          field: 'display_name',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Действия'),
          field: 'action',
        },
      ]
    },
  },
  watch: {
    page(newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    'filterModel.per_page': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
  },
  mounted() {
    this.getItems()
  },
  methods: {
    ...mapActions({
      getItemsAction: 'role/index',
      destroyAction: 'role/destroy',
    }),
    async getItems() {
      this.loading = true
      await this.getItemsAction({ ...this.filterModel, page: this.page, relations: 'Permissions' })
      this.loading = false
    },
    destroy(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t('Вы действительно хотите удалить?'), {
          title: this.$t('Подтвердите'),
          size: 'sm',
          variant: 'warning',
          okVariant: 'primary',
          okTitle: this.$t('Да'),
          cancelTitle: this.$t('Нет'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.destroyAction(id).then(res => {
              showToast('success', this.$t('Успешно удалено'))
              this.getItems()
            }).catch(() => {
              showToast('success', this.$t('Успешно удалено'))
            })
          }
        })
    },
  },
}
</script>

<style scoped>

</style>
