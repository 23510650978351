<template>
  <b-modal
    v-model="visible"
    cancel-variant="outline-secondary"
    :ok-title="$t('Сохранить')"
    no-close-on-backdrop
    :cancel-title="$t('Отмена')"
    centered
    :title="form.id ? $t('Изменить роль') : $t('Добавить роль')"
    @ok.prevent="save"
  >
    <validation-observer
      ref="form"
      #default="{invalid}"
    >
      <b-form
        class="auth-login-form mt-2"
      >
        <b-form-group
          :label="$t('Имя')"
          label-for="name"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Имя')"
            rules="required"
          >
            <b-form-input
              id="name"
              v-model="form.name"
              :state="errors.length > 3 ? false:null"
              name="name"
              placeholder=""
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          :label="$t('Описание')"
          label-for="display_name"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Описание')"
            rules="required"
          >
            <b-form-input
              id="display_name"
              v-model="form.display_name"
              :state="errors.length > 3 ? false:null"
              :name="$t('Описание')"
              :placeholder="$t('Описание')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          :label="$t('Huquqlar berish')"
        >
          <el-tree
            ref="tree"
            :data="permissions"
            show-checkbox
            node-key="id"
            :default-expanded-keys="[]"
            :default-checked-keys="form.permissions"
            :props="props"
          />
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions } from 'vuex'
import { clearObject } from '@/utils'

export default {
  name: 'Create',
  components: {
    ValidationProvider, ValidationObserver,
  },
  data() {
    return {
      form: {
        id: null,
        name: null,
        display_name: null,
        permissions: null,
      },
      permissions: [],
      props: {
        label: 'display_name',
        children: 'children',
      },
      title: '',
      visible: false,
      required,
    }
  },
  computed: {
    disabledPermissions() {
      let perms = []
      if (this.permissions.length) {
        perms = this.permissions.map(p => {
          let ch = p.children
          ch = ch.map(t => t)
          p.children = ch
          return p
        })
      }
      return perms
    },
  },
  watch: {
    visible(newVal) {
      if (!newVal) setTimeout(() => { clearObject(this.form) }, 200)
    },
  },
  mounted() {
    this.$root.$on('add', data => {
      this.visible = true
    })
    this.getPermissions().then(res => {
      this.permissions = this.list_to_tree(res.data) ? this.list_to_tree(res.data) : res.data
    })
  },
  methods: {
    async save() {
      const valid = await this.validationForm()
      if (valid) {
        const perms = this.$refs.tree.getCheckedNodes()
        this.form.permissions = perms.map(p => p.id)
        this.method(this.form).then(res => {
          showToast('success', this.$t('Успешно сохранено'), 'CheckCircleIcon')
          this.$emit('onSuccess')
          this.visible = false
        }).catch(err => {
          if (err.status === 422) {
            this.$refs.form.setErrors(err.data.errors)
          }
          showToast('danger', this.$t('Ошибка'), 'XIcon')
        })
      } else {
        showToast('warning', this.$t('Заполните необходимые поля'))
      }
    },
    edit(item) {
      this.form.id = item.id
      this.form.name = item.name
      this.form.display_name = item.display_name
      this.form.permissions = item.permissions.map(p => p.id)
      this.visible = true
    },
    method(data) {
      if (this.form.id) return this.updateItem(data)
      return this.storeItem(data)
    },
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    list_to_tree(list) {
      const map = {}
      let node
      const roots = []
      let i
      for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i // initialize the map
        list[i].children = [] // initialize the children
      }

      for (i = 0; i < list.length; i += 1) {
        node = list[i]
        if (node.parent_id !== null) {
          // if you have dangling branches check that map[node.parent_id] exists
          list[map[node.parent_id]].children.push(node)
        } else {
          roots.push(node)
        }
      }
      return roots
    },
    ...mapActions({
      storeItem: 'role/store',
      updateItem: 'role/update',
      getPermissions: 'resource/getPermissions',
    }),
  },
}
</script>

<style scoped>

</style>
